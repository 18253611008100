import React, { useState, useEffect } from 'react';
import { useParams , Link } from 'react-router-dom';
import { collection, query, where, getDocs } from 'firebase/firestore';

function PostPage({db, user}) {
	const [postContent, setPostContent] = useState("");
	const [postTitle, setPostTitle] = useState("");
	const [postAuthor, setPostAuthor] = useState("");
	const [postDate, setPostDate] = useState("");
	const [userIsPaidSubscriber, setUserIsPaidSubscriber] = useState(false);
	const [isSubscriberOnly, setIsSubscriberOnly] = useState(false);
	const [premiumAvailable, setPremiumAvailable] = useState(false);
	const {urlStub} = useParams();

	useEffect(() => {
		async function fetchPost() {
			const postsRef = collection(db, 'posts');
			const q = query(postsRef, where('url-index', '==', urlStub));
			const querySnapshot = await getDocs(q);

			if(!querySnapshot.empty) {
				const postDoc = querySnapshot.docs[0].data();
				setIsSubscriberOnly(postDoc.subscriberOnly);
				setPremiumAvailable(postDoc.premiumAvailable);
				setUserIsPaidSubscriber(false);
				if(!postDoc.subscriberOnly || (user && user.isPaidSubscriber)) {
					setPostContent(postDoc.content);
					setPostTitle(postDoc.title);
					setPostAuthor(postDoc.author);
					setPostDate(postDoc.postDate?.toDate().toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric', }));
				} else {
					setPostContent('This content is for subscribers only.')}
			} else {
				setPostContent('Post not found');
			}
		}

		fetchPost();
	}, [db, urlStub, user]);


	if(false && isSubscriberOnly && (!user || !user.isPaidSubscriber)) {
		return(
			<div className="article">
			<h1 className="title">Subscriber-Only Content</h1>
			<div className="metaData">
			<span className="author">{postAuthor}</span>
			<span className="date">{postDate}</span>
			</div>
			<p>This content is available only to paid subscribers.</p>
			{!user && <p>Please <Link to="/login">log in</Link> if you're already a subscriber.</p> }
				{user && !user.isPaidSubscriber && <p>To access this content, please <Link to="/subscribe">subscribe</Link>.</p>}
				</div>
			);}




			return (
				<article className="article">
				<h1 className="title">{postTitle}</h1>
				<div className="content" dangerouslySetInnerHTML={{__html: postContent}} />
				<div className="metaData">
				<p><span className="author">{postAuthor}</span></p>
				<span className="date">{postDate}</span>
				</div>
				{
					premiumAvailable && !isSubscriberOnly && !userIsPaidSubscriber &&
					<div style={{marginTop: '30px', padding: '20px', backgroundColor: '#ddd9d9', color: '#383838'}}>

					<h2>Need the full report?</h2>
					<p>You get the final answers that we find from our research in the free article above. That is likely to be all you need.</p>

					<p> The full report provides more detail and helps people build mental models of the future. This is helpful for leaders at large companies and for investors. It can help make better big-picture decisions.</p>


					<p>You can access all our full reports by purchasing our premium subscription. <a href="/subscription-enquiry" style={{color: "gray"}}>Click here to subscribe</a>.</p>
					{ /*<p>Full reports are available with our premium subscriptions. Our premium subscription is US$4,000/month. <a href="/subscription-enquiry" style={{color: "gray"}}>Click here to subscribe.</a></p> */ }
					</div>
				}
				</article>
			);
	}

	export default PostPage;

