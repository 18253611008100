import React from 'react';
import {Link} from 'react-router-dom';

function HomePage({user}){
	return (
		<div className='homepg'>
		<div className="homepage-background"></div>
				<div style={{paddingTop: '50px', paddingBottom: '50px'}} className="article">
				<h1 style={{marginBottom: '50px'}} className="title">Welcome to the Big Changes</h1>

<p>The world is going to look very different in 25 years.</p>

<p>We are in a period of major change - driven by forces including demographics, technology and geopolitics.</p>


{/*
<p>The big changes include AI, aging societies and quantum computing.</p>
<p>The big changes include AI, aging societies in developed countries, the wealth and power of different countries, robots, biotechnology, rising individual wealth levels, wealth distribution, cultural norms, global institutions, climate policies, and quantum computing.</p>
<p>By using this analysis, decision makers can make better choices for companies and governments - making for a better future.</p>
*/}


<p>We need to understand what the world will look like over the next 25 years to make good decisions today for our businesses and societies.</p>

<p>The Big Changes does research, analysis and modelling work to forecast how the world will change over the coming decades.</p>



<h2 style={{marginTop: '60px', marginBottom: '30px'}}>Research reports</h2>

<p><a href="/how-consumer-demand-will-change-over-the-next-25-years">How Consumer Demand Will Change Over The Next 25 Years</a></p>
<p><a href="/how-ai-will-change-employment">How AI Will Change Employment</a></p>
		{/*
		{user ? (
			<p>Welcome, {user.email}! {!user.isPaidSubscriber && <Link to='/subscribe'>Subscribe</Link>}</p>) : (
				<p><Link to="login">Log in</Link> or <Link to="/subscribe">Subscribe</Link></p>
			)}
			*/}
		</div>
	</div>

	);
}

export default HomePage;
